import { Keyboard } from 'types/keyboard'

export function handleEscapeKeyPress(
	event: KeyboardEvent,
	updateOpen: (newValue: boolean) => void
) {
	if (event.key === Keyboard.Escape) {
		updateOpen(false)
	}
}
