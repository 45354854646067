'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational'
import { CommonGenericEvents, SectionParameters } from 'header/constants'
import { IconFavoffL } from 'icons/components/IconFavoffL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { FavoritesClientLink } from 'links/favorites/client'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import iconStyles from '../Icons.module.scss'

export function Favorites() {
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()

	const handleClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_HEADER, {
			section: SectionParameters.FAVORITES,
		})
	}

	return (
		<FavoritesClientLink
			className={iconStyles.link}
			onClick={handleClick}
			linkProps={{
				'data-testid': 'header.userMenu.favorites_any',
				'aria-label': t('accessibility.header.wishlist.icon'),
				rel: 'nofollow',
			}}
			linkComponent={isLargeOrGreater ? InspirationalClientLink : undefined}
		>
			<IconFavoffL className={iconStyles.icon} />
			<span className={iconStyles.text}>{t('header.wishlist.text')}</span>
		</FavoritesClientLink>
	)
}
