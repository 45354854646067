'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { BUTTON_INSPIRATIONAL_VARIANT } from 'fukku/Button/Inspirational/constants'
import { ButtonInspirational } from 'fukku/Button/Inspirational/index'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { getEntryState } from 'header/utils/menu/getEntryState'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { CommonGenericEvents } from '../../../../constants'

type EntrySubmenuProps = {
	section: MenuEntry
	className: string
	isSelected: boolean | undefined
	dataTestIdCategory: string
	eventData: EntryClickMenuEventData
	onClick: () => void
	handleKeyDown?: (event: React.KeyboardEvent) => void
	isPromotion?: boolean
}
export function EntrySubmenu({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	onClick,
	handleKeyDown,
	isPromotion = false,
}: EntrySubmenuProps) {
	const { id, labelId } = section
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()

	const handleClick = () => {
		if (!isLargeOrGreater) {
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
		}
		onClick()
	}

	return (
		<ButtonInspirational
			className={className}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			data-testid={`menu.${dataTestIdCategory}.${id}.family`}
			state={getEntryState(isSelected)}
			variant={
				isPromotion
					? BUTTON_INSPIRATIONAL_VARIANT.PROMOTION
					: BUTTON_INSPIRATIONAL_VARIANT.DEFAULT
			}
			aria-expanded={isSelected}
		>
			{t(labelId)}
		</ButtonInspirational>
	)
}
