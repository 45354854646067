import { Device } from 'types/masterData'

export const logoSize = {
	shop: {
		[Device.Desktop]: {
			width: {
				mango: 108,
				mng: 56,
			},
			height: {
				mango: 18,
				mng: 16,
			},
		},
		[Device.Mobile]: {
			width: {
				mango: 96,
				mng: 56,
			},
			height: {
				mango: 16,
				mng: 16,
			},
		},
	},
	outlet: {
		[Device.Desktop]: {
			width: {
				mango: 148,
				mng: 56,
			},
			height: {
				mango: 16,
				mng: 16,
			},
		},
		[Device.Mobile]: {
			width: {
				mango: 148,
				mng: 56,
			},
			height: {
				mango: 16,
				mng: 16,
			},
		},
	},
	backoffice: {
		[Device.Desktop]: {
			width: {
				mango: 206,
				mng: 91,
			},
			height: {
				mango: 26,
				mng: 26,
			},
		},
		[Device.Mobile]: {
			width: {
				mango: 124,
				mng: 49,
			},
			height: {
				mango: 14,
				mng: 14,
			},
		},
	},
}
