import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'header/constants'
import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { useMenus } from 'header/hooks/useMenus'
import { IconCloseS1 } from 'icons/components/IconCloseS1'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { Keyboard } from 'types/keyboard'

import { MenuRefType } from '../../../../context/MenuContext/MenuRefContext'
import { getFirstAndLastElement } from '../../../../utils/menu/focusOrder/getFirstAndLastElement'

import styles from './CloseButton.module.scss'

interface CloseProps {
	action?: () => void
}

export function CloseButton({ action }: CloseProps) {
	const {
		brandSelected,
		refs: { refBrands },
		updateOpen,
		updateRefs,
	} = useMenus()
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()
	const refCloseButton = useRef<HTMLButtonElement>(null)

	const handleMenuClose = () => {
		action?.()
		updateOpen(false)
		sendGenericEvent(CommonGenericEvents.CLOSE_MENU, {
			brand: brandAdapter[brandSelected as string],
		})
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (
			event.key === Keyboard.Tab &&
			!event.shiftKey &&
			refBrands.current &&
			isLargeOrGreater
		) {
			event.preventDefault()
			const { firstElement } = getFirstAndLastElement(refBrands.current)
			firstElement?.focus()
		}
	}

	useEffect(() => {
		if (refCloseButton.current) {
			updateRefs(MenuRefType.CLOSE_BUTTON, refCloseButton)
		}
	}, [refCloseButton.current])

	return (
		<button
			ref={refCloseButton}
			className={styles.closeButton}
			onClick={handleMenuClose}
			onKeyDown={handleKeyDown}
			aria-label={t('accessibility.icon.cross.close.text')}
			data-testid='menu.close'
		>
			<IconCloseS1 />
		</button>
	)
}
