'use client'

import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { LOGIN_URL } from '../constants'
import { StorePreviousUrl } from '../shared/StorePreviousUrl'

type LoginClientLinkProps = Omit<ClientLinkProps, 'href'> & {
	linkComponent?: React.ElementType
}

export function LoginClientLink({
	children,
	linkComponent,
	...linkProps
}: LoginClientLinkProps) {
	const Component = linkComponent || ClientLink

	return (
		<>
			<StorePreviousUrl />
			<Component {...linkProps} withLegacyLocale={false} href={LOGIN_URL}>
				{children}
			</Component>
		</>
	)
}
