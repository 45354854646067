'use client'

import { useCartDispatch, useCartState } from 'cart/context/CartProvider'
import { IconBagL } from 'icons/components/IconBagL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { CartClientLink } from 'links/cart/client/CartClientLink'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'

import { TotalItems } from '../TotalItems/TotalItems'

import styles from './ShoppingCartButton.module.scss'

const LimitsModal = dynamic(() =>
	import('../../../components/LimitsModal/LimitsModal').then(
		(module) => module.LimitsModal
	)
)

const AddNoStockModal = dynamic(() =>
	import('../../../components/AddNoStockModal/AddNoStockModal').then(
		(module) => module.AddNoStockModal
	)
)

export const ShoppingCartSmall = (): ReactNode => {
	const { t } = useLabels()
	const dispatch = useCartDispatch()

	const { openedLimitWarningModal, openedAddNoStockModal } = useCartState()

	const handleClick = (): void => {
		dispatch({ showShoppingCart: true })
	}

	return (
		<div className={styles.userIconContainer}>
			<CartClientLink
				linkProps={{
					rel: 'nofollow',
				}}
				className={styles.userIcon}
				onClick={handleClick}
				id='header.userMenu.cart.button'
				data-testid={'header.userMenu.cart.button'}
			>
				<span className={styles.iconWrapper}>
					<IconBagL
						className={styles.icon}
						alt={t('shoppingBag.empty.title')}
					/>
					<TotalItems withLabel={false} icon />
				</span>
			</CartClientLink>

			{openedLimitWarningModal && <LimitsModal />}
			{openedAddNoStockModal && <AddNoStockModal />}
		</div>
	)
}
