import { getFocusableElements } from './getFocusableElements'

export function getFirstAndLastElement(divElement: HTMLElement): {
	firstElement: HTMLElement
	lastElement: HTMLElement
} {
	const focusableElements = getFocusableElements(divElement)
	const firstElement = focusableElements[0]
	const lastElement = focusableElements[focusableElements.length - 1]

	return { firstElement, lastElement }
}
