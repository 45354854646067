'use client'

import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational'
import { IconAccountL } from 'icons/components/IconAccountL'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { LoginClientLink } from 'links/login/client'
import {
	MyAccountClientLink,
	type MyAccountClientLinkProps,
} from 'links/myAccount/client'
import {
	type FocusEvent,
	type MouseEvent,
	type PointerEvent,
	useEffect,
	useState,
} from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { useSession } from 'session/src/hooks/useSession'

import iconStyles from '../Icons.module.scss'

let UserLink = LoginClientLink

export type AccountIconProps = {
	open?: () => void
	close?: () => void
	refAccountMenu?: React.RefObject<HTMLDivElement>
}

export const AccountIcon = ({
	open,
	close,
	refAccountMenu,
}: AccountIconProps) => {
	const { isLargeOrGreater } = useResponsive()
	const { t } = useLabels()
	const { logged } = useSession()
	const labels = {
		logged: {
			text: t('header.myAccount.text'),
			acc: t('accessibility.header.myAccount.icon'),
		},
		notLogged: {
			text: t('header.myAccount.pushLogin.text'),
			acc: t('accessibility.header.myAccount.pushLogin.icon'),
		},
	}

	const [labelAccount, setLabelAccount] = useState(labels.notLogged.text)

	useEffect(() => {
		if (logged) {
			setLabelAccount(labels.logged.text)
			UserLink = MyAccountClientLink
		} else {
			setLabelAccount(labels.notLogged.text)
			UserLink = LoginClientLink
		}
	}, [logged])

	const handleOpen = () => {
		open && open()
	}

	const handlerBlur = (event: FocusEvent<HTMLAnchorElement>) => {
		const { relatedTarget } = event
		const current = refAccountMenu?.current

		if (!current || current?.contains(relatedTarget as Node)) {
			return
		}

		close && close()
	}

	const handleClose = (event: MouseEvent | PointerEvent) => {
		const { target } = event
		const current = refAccountMenu?.current

		if (!current) {
			return
		}

		const currenTarget = current?.contains(target as Node)

		if (!currenTarget) {
			close && close()
		}
	}

	const linkProps: MyAccountClientLinkProps['linkProps'] = {
		rel: 'nofollow',
		'aria-label': logged ? labels.logged.acc : labels.notLogged.acc,
		...(isLargeOrGreater
			? {
					'data-testid': 'header.userMenu.login_any',
					onBlur: handlerBlur,
					onFocus: handleOpen,
					onPointerEnter: handleOpen,
					onPointerLeave: handleClose,
				}
			: {
					'data-testid': 'header.userMenu.login_mobile_any',
				}),
	}

	return (
		<UserLink
			className={iconStyles.link}
			linkProps={linkProps}
			linkComponent={isLargeOrGreater ? InspirationalClientLink : undefined}
		>
			<IconAccountL className={iconStyles.icon} />
			<span className={iconStyles.text}>{labelAccount}</span>
		</UserLink>
	)
}
