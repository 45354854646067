import type { Brand } from 'types/brands'

import { flatBrands } from './flatBrands'

export const getBrandTranslationKeys = (
	brandId: Brand['id'],
	brands: Brand[]
) => {
	const foundBrand = flatBrands(brands).find((brand) => brand.id === brandId)

	if (!foundBrand) {
		return undefined
	}

	return {
		labelKey: foundBrand.labelKey,
		urlKey: foundBrand.urlKey,
	}
}
