import { useEffect, useRef, useState } from 'react'
import type { RefObject } from 'react'

const DEFAULT_HEADER_HEIGHT = 56

export const useHeaderHeight = (): {
	headerHeight: number
	headerRef: RefObject<HTMLHeadElement>
} => {
	const [headerHeight, setHeaderHeight] = useState<number>(
		DEFAULT_HEADER_HEIGHT
	)
	const headerRef = useRef<HTMLHeadElement | null>(null)
	const setStylesVariable = () => {
		document.documentElement.style.setProperty(
			'--header-height',
			`${headerHeight}px`
		)
	}

	useEffect(() => {
		headerRef.current = document.getElementById('header')

		const updateHeaderHeight = () => {
			if (headerRef.current) {
				const { height } = headerRef.current.getBoundingClientRect()
				setHeaderHeight(height)
				setStylesVariable()
			}
		}

		updateHeaderHeight()

		const resizeObserver = new ResizeObserver(updateHeaderHeight)

		if (headerRef.current) {
			resizeObserver.observe(headerRef.current)
		}

		return () => {
			resizeObserver.disconnect()
		}
	}, [])

	return {
		headerHeight,
		headerRef,
	}
}
