import { getPropertyStructureMinWidth } from 'header/utils/menu/minWidthForStructureLPlus'
import { useEffect, useState } from 'react'

import { useMenus } from './useMenus'

export function useAnimationMenu(brandSelected: string | null) {
	const {
		refs: { refContent },
	} = useMenus()
	const structureMinWidth = getPropertyStructureMinWidth()

	const initialWidth = Math.max(
		structureMinWidth,
		refContent.current?.clientWidth ?? 0
	)
	const [widthAside, setWidthAside] = useState<number>(initialWidth)
	const [selectedEntries, setSelectedEntries] = useState<string[]>([])

	const calculateWidth = () => {
		const current = refContent.current
		if (!current) {
			return
		}

		setWidthAside(Math.max(current.clientWidth, structureMinWidth))
	}

	useEffect(() => {
		calculateWidth()
	}, [selectedEntries, brandSelected, refContent.current])

	return {
		refContent,
		widthAside,
		selectedEntries,
		setSelectedEntries,
	}
}
