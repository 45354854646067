'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents, SectionParameters } from 'header/constants'
import dynamic from 'next/dynamic'
import { useRef, useState } from 'react'

import { AccountIcon } from '../Account'

const AccountMenu = dynamic(() =>
	import('./AccountMenu').then((mod) => mod.AccountMenu)
)

export const WrapperAccountMenu = ({
	hasFavorites,
}: {
	hasFavorites: boolean
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const refAccountMenu = useRef<HTMLDivElement>(null)

	const toggle = () => {
		setIsOpen((current) => !current)
	}
	const open = () => {
		if (!isOpen) {
			sendGenericEvent(CommonGenericEvents.DISPLAY_HEADER, {
				section: SectionParameters.MY_ACCOUNT,
			})
		}
		toggle()
	}

	const close = () => setIsOpen(false)

	return (
		<>
			<AccountIcon open={open} close={close} refAccountMenu={refAccountMenu} />
			{isOpen ? (
				<AccountMenu
					open={open}
					close={close}
					refAccountMenu={refAccountMenu}
					hasFavorites={hasFavorites}
				/>
			) : null}
		</>
	)
}
