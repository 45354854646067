'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational/index'
import { BUTTON_INSPIRATIONAL_VARIANT } from 'fukku/Button/Inspirational/constants'
import { CommonGenericEvents } from 'header/constants'
import { useCorrectUrlNewResponse } from 'header/hooks/useCorrectUrlNewResponse'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { getEntryState } from 'header/utils/menu/getEntryState'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { usePathname } from 'next/navigation'

type EntryLinkProps = {
	section: MenuEntry
	className: string
	isSelected: boolean | undefined
	dataTestIdCategory: string
	eventData: EntryClickMenuEventData
	handleKeyDown?: (event: React.KeyboardEvent) => void
	isPromotion: boolean
}

export function EntryLink({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	handleKeyDown,
	isPromotion,
}: EntryLinkProps) {
	const { id, labelId } = section
	const { t } = useLabels()
	const url = useCorrectUrlNewResponse(section)
	const pathname = usePathname()

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
	}

	return (
		<InspirationalClientLink
			href={url || ''}
			className={className}
			isLocalizedPath
			linkProps={{
				'data-testid': `menu.${dataTestIdCategory}.${id}.link`,
				'aria-current': pathname === url ? 'page' : undefined,
				onKeyDown: handleKeyDown,
			}}
			state={getEntryState(isSelected)}
			onClick={handleLinkClick}
			variant={
				isPromotion
					? BUTTON_INSPIRATIONAL_VARIANT.PROMOTION
					: BUTTON_INSPIRATIONAL_VARIANT.DEFAULT
			}
		>
			{t(labelId)}
		</InspirationalClientLink>
	)
}
