import { cx } from 'utils/cx'

import type { ButtonProps } from '../Button.types'
import { BUTTON_VARIANT } from '../Button.types'
import { ButtonBase } from '../ButtonBase'
import { ButtonContentWrapper } from '../ButtonContentWrapper/ButtonContentWrapper'

import styles from './ButtonSecondary.module.scss'

export function ButtonSecondary(props: ButtonProps): React.ReactNode {
	const {
		variant = BUTTON_VARIANT.DEFAULT,
		isLoading = false,
		className,
		children,
		fullWidth,
		accessibilityText,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(className, styles[variant], fullWidth && styles.fullWidth)}
		>
			<ButtonContentWrapper
				isLoading={isLoading}
				variant={BUTTON_VARIANT.INVERSE}
				accessibilityText={accessibilityText}
			>
				{children}
			</ButtonContentWrapper>
		</ButtonBase>
	)
}
