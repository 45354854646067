import type { Brand } from 'types/brands'

// return all brands and subbrands at same level
export const flatBrands = (brands: Brand[]): Brand[] => {
	return brands.reduce((flattedBrands, brand) => {
		const { subBrands } = brand

		if (subBrands) {
			return [...flattedBrands, brand, ...flatBrands(subBrands)]
		}

		return [...flattedBrands, brand]
	}, [] as Brand[])
}
