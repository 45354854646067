import { BUTTON_INSPIRATIONAL_STATE } from 'fukku/Button/Inspirational/constants'

export function getEntryState(
	isSelected: boolean | undefined = undefined
): BUTTON_INSPIRATIONAL_STATE {
	if (isSelected === undefined) {
		return BUTTON_INSPIRATIONAL_STATE.DEFAULT
	}

	return isSelected
		? BUTTON_INSPIRATIONAL_STATE.ACTIVE
		: BUTTON_INSPIRATIONAL_STATE.UNSELECTED
}
