import { useContext } from 'react'

import { MenuRefDispatchContext } from './MenuRefContext'

export const useMenuRefDispatch = () => {
	const context = useContext(MenuRefDispatchContext)

	if (context === undefined) {
		throw new Error('useMenuDispatch must be used within a MenuProvider')
	}

	return context
}
