'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useMenusLegacy } from 'header/hooks/useMenusLegacy'
import { useMenuService } from 'header/services/menu/client'
import type { MenuService } from 'header/types/menu'
import type { MenuServiceLegacy } from 'header/types/menu/menuLegacy'
import { adapterNewMenuService } from 'header/utils/menu/adapterNewMenuService'
import { useClientLabels } from 'labels/hooks/useClientLabels/useClientLabels'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useSetLabels } from 'labels/hooks/useSetLabels/useSetLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useMemo } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { fetcher } from 'services/rest/fetcher'
import useSWRImmutable from 'swr/immutable'
import { LabelsNamespaces } from 'types/labels'
import { Device, type MasterData } from 'types/masterData'
import { isBot } from 'utils/isBot/IsBot'

import { MenuAdaptive } from './MenuAdaptive'

type RevalidateMenuProps = {
	data: MenuServiceLegacy | MenuService
	brand: MasterData['brand']
}

export function RevalidateMenu({ data, brand }: RevalidateMenuProps) {
	const {
		country: { countryISO, languageISO },
		isShop,
	} = useMasterData()
	const { open, updateBrandSelected } = useMenusLegacy()
	const { labels: layoutLabels } = useLabels()
	const setLabels = useSetLabels()
	const { getMenuServiceUrlClient } = useMenuService()

	const { enabled: FflagEditActivation } = useFeatureFlag(
		featureFlagKeys.EditActivation
	)
	const { enabled: FflagCatalogActivation } = useFeatureFlag(
		featureFlagKeys.PlpVersion
	)

	const { isLessThanLarge } = useResponsive()

	const device = !isLessThanLarge ? Device.Desktop : Device.Mobile

	const shouldRevalidate = !isBot() && open

	const { data: revalidateData } = useSWRImmutable<MenuService>(
		shouldRevalidate ? getMenuServiceUrlClient() : null,
		fetcher
	)

	const menuData = revalidateData || data

	const adaptMenuData = useMemo(
		() =>
			adapterNewMenuService(
				menuData as MenuService,
				device,
				FflagEditActivation,
				FflagCatalogActivation
			),
		[menuData, device]
	)

	const menuNamespace = isShop
		? LabelsNamespaces.Menu_Shop
		: LabelsNamespaces.Menu_Outlet

	const { labels: labelsClient } = useClientLabels({
		namespaces: open ? [menuNamespace, LabelsNamespaces.Menu_Common] : [],
		suspense: false,
		countryISO,
		languageISO,
	})

	useEffect(() => {
		if (layoutLabels && labelsClient) {
			setLabels({
				...layoutLabels,
				...labelsClient,
			})
		}
	}, [labelsClient])

	useEffect(() => {
		if (brand) {
			updateBrandSelected(brand)
		}
	}, [brand])

	return <MenuAdaptive data={adaptMenuData} />
}
