const CANONICAL_DECOMPOSITION_FLAG = 'NFD'
const REGEX_UNICODE_MARKS = /\p{Mark}/gu
const REGEX_BREAKING_CHARACTERS = /[\s\u00A0,\/]/g

export const urlNormalize = (str: string): string =>
	encodeURIComponent(
		str
			.normalize(CANONICAL_DECOMPOSITION_FLAG)
			.replace(REGEX_UNICODE_MARKS, '')
			.trim()
			.replace(REGEX_BREAKING_CHARACTERS, '-')
			.toLocaleLowerCase()
	)
