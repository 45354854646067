import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { ButtonBase } from 'fukku/Button/ButtonBase'
import { applyStylesIf, cx } from 'utils/cx'

import { LINK_SIZE } from '../InspirationalLink/InspirationalLink'

import styles from './InspirationalButtonLink.module.scss'

interface InspirationalLinkProps extends React.ComponentProps<'button'> {
	size?: LINK_SIZE
	variant?: BUTTON_VARIANT
	active?: boolean
}

export function InspirationalButtonLink(
	props: InspirationalLinkProps
): React.ReactNode {
	const {
		children,
		disabled,
		active,
		variant = BUTTON_VARIANT.DEFAULT,
		size = LINK_SIZE.M,
		className,
		...rest
	} = props

	return (
		// TODO: Don't use ButtonBase
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles[variant],
				styles[size],
				applyStylesIf(active === true && !disabled, styles.active),
				applyStylesIf(active === false && !disabled, styles.unselected)
			)}
			disabled={disabled}
		>
			<span className={styles.text}>{children}</span>
		</ButtonBase>
	)
}
