'use client'

import { cx } from 'utils/cx'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import {
	BUTTON_INSPIRATIONAL_STATE,
	BUTTON_INSPIRATIONAL_VARIANT,
	LINK_SIZE,
	linkInspirationalSizeStyles,
	stateStyles,
} from '../../Inspirational/constants'

import styles from '../../Inspirational/ButtonInspirational.module.scss'

type ClientInspirationalLinkProps = ClientLinkProps & {
	size?: LINK_SIZE
	variant?: BUTTON_INSPIRATIONAL_VARIANT
	state?: BUTTON_INSPIRATIONAL_STATE
}

export function InspirationalClientLink(
	props: ClientInspirationalLinkProps
): React.ReactNode {
	const {
		children,
		state = BUTTON_INSPIRATIONAL_STATE.DEFAULT,
		variant = BUTTON_INSPIRATIONAL_VARIANT.DEFAULT,
		size = LINK_SIZE.M,
		className,
		...rest
	} = props

	return (
		<ClientLink
			{...rest}
			className={cx(
				className,
				styles.buttonInspirational,
				styles[variant],
				linkInspirationalSizeStyles[size],
				stateStyles[state]
			)}
		>
			<span className={styles.text}>{children}</span>
		</ClientLink>
	)
}
