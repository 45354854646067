'use client'

import type { BrandEntry } from 'header/types/menu'
import { useEffect } from 'react'

import { useMenus } from '../../../../hooks/useMenus'
import { SubBrandTab } from './SubBrandTab/SubBrandTab'

import styles from './SubBrands.module.scss'

interface SubBrandsProps {
	subBrands: BrandEntry[]
}

export function SubBrands({ subBrands }: SubBrandsProps) {
	const { open, subBrandSelected, updateSubBrandSelected } = useMenus()
	const [firstSubBrand] = subBrands
	const defaultSubBrand = subBrandSelected ?? firstSubBrand.id

	useEffect(() => {
		if (open && !subBrandSelected) {
			updateSubBrandSelected(defaultSubBrand)
		}
	}, [open, subBrandSelected])

	return (
		<div className={styles.suBrandsWrapper}>
			<ul className={styles.subBrands}>
				{subBrands.map((subBrand) => (
					<SubBrandTab key={subBrand.id} subBrand={subBrand} />
				))}
			</ul>
		</div>
	)
}
