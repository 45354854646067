import type { MenuEntry } from 'header/types/menu'
import { Keyboard } from 'types/keyboard'

import { getFirstAndLastElement } from './getFirstAndLastElement'
import { getFocusableElements } from './getFocusableElements'

function getNextElementIndex(
	index: number,
	selectedEntries: string[],
	visibleMenus: (MenuEntry[] | undefined)[]
) {
	const selectedEntry = selectedEntries[index]
	const columnMenu = visibleMenus[index]
	return columnMenu
		? columnMenu.findIndex((menu) => menu.id === selectedEntry) + 1
		: 0
}

function handleFirstColumn(props: {
	event: React.KeyboardEvent
	entry: MenuEntry
	index: number
	selectedEntries: string[]
	refBrandsCurrent: HTMLUListElement | null
	brandSelectedIndex: number
	refCloseButton: React.RefObject<HTMLButtonElement>
	wrapper: HTMLDivElement
	firstElement: HTMLElement
	focusedElement: Element | null
}) {
	const {
		event,
		entry,
		index,
		selectedEntries,
		refBrandsCurrent,
		brandSelectedIndex,
		refCloseButton,
		wrapper,
		firstElement,
		focusedElement,
	} = props
	if (entry.id === selectedEntries[index] && !event.shiftKey) {
		event.preventDefault()
		firstElement.focus()
		return
	}
	const { lastElement: firstColumnLastElement } = getFirstAndLastElement(
		wrapper.children[0] as HTMLElement
	)
	if (focusedElement === firstColumnLastElement && !event.shiftKey) {
		const anchorElement = refBrandsCurrent?.querySelectorAll('a')
		event.preventDefault()

		if (anchorElement && brandSelectedIndex + 1 < anchorElement.length) {
			event.preventDefault()
			const brandElementToFocus = anchorElement[brandSelectedIndex + 1]
			brandElementToFocus?.focus()
		} else {
			refCloseButton.current?.focus()
		}
	}
}

function handleNonFirstColumn(props: {
	event: React.KeyboardEvent
	index: number
	selectedEntries: string[]
	visibleMenus: (MenuEntry[] | undefined)[]
	refBrandsCurrent: HTMLUListElement | null
	brandSelectedIndex: number
	refCloseButton: React.RefObject<HTMLButtonElement>
	wrapper: HTMLDivElement
	firstElement: HTMLElement
	lastElement: HTMLElement
	focusedElement: Element | null
}) {
	const {
		event,
		index,
		selectedEntries,
		visibleMenus,
		refBrandsCurrent,
		brandSelectedIndex,
		refCloseButton,
		wrapper,
		firstElement,
		lastElement,
		focusedElement,
	} = props
	const priorColumnIndex = index - 1
	if (focusedElement === firstElement && event.shiftKey) {
		event.preventDefault()

		const focusElementIndex = getNextElementIndex(
			priorColumnIndex,
			selectedEntries,
			visibleMenus
		)
		const focusLiElement =
			wrapper.children[priorColumnIndex].children[focusElementIndex - 1]

		const focusElement = getFocusableElements(focusLiElement)[0]
		focusElement.focus()
		return
	}
	if (focusedElement === lastElement && !event.shiftKey) {
		event.preventDefault()

		const focusElementIndex = getNextElementIndex(
			priorColumnIndex,
			selectedEntries,
			visibleMenus
		)
		if (focusElementIndex === visibleMenus[priorColumnIndex]?.length) {
			const anchorElement = refBrandsCurrent?.querySelectorAll('a')
			event.preventDefault()

			if (anchorElement && brandSelectedIndex + 1 < anchorElement.length) {
				event.preventDefault()
				const brandElementToFocus = anchorElement[brandSelectedIndex + 1]
				brandElementToFocus?.focus()
			} else {
				refCloseButton.current?.focus()
			}
			return
		}

		const focusLiElement =
			wrapper.children[priorColumnIndex].children[focusElementIndex]

		const focusElement = getFocusableElements(focusLiElement)[0]
		focusElement.focus()
	}
}

export function manageFocusedElementColumnsLPlus(props: {
	event: React.KeyboardEvent
	entry: MenuEntry
	index: number
	refWrapper: React.RefObject<HTMLDivElement>
	selectedEntries: string[]
	visibleMenus: (MenuEntry[] | undefined)[]
	refBrands: React.RefObject<HTMLUListElement>
	brandSelectedIndex: number
	refCloseButton: React.RefObject<HTMLButtonElement>
}): void {
	const {
		event,
		entry,
		index,
		refWrapper,
		selectedEntries,
		visibleMenus,
		refBrands,
		brandSelectedIndex,
		refCloseButton,
	} = props
	const wrapper = refWrapper.current
	if (!wrapper) {
		return
	}

	const refBrandsCurrent = refBrands.current
	const numberOfColumns = wrapper.childElementCount
	const focusedElement = document.activeElement
	const adjustedIndex = index === 0 && numberOfColumns > 1 ? index + 1 : index
	const { firstElement, lastElement } = getFirstAndLastElement(
		wrapper.children[adjustedIndex] as HTMLElement
	)

	if (event.key === Keyboard.Tab) {
		if (index !== 0) {
			const handleProps = {
				event,
				index,
				selectedEntries,
				visibleMenus,
				refBrandsCurrent,
				brandSelectedIndex,
				refCloseButton,
				wrapper,
				firstElement,
				lastElement,
				focusedElement,
			}
			handleNonFirstColumn(handleProps)
		} else {
			const handleProps = {
				event,
				entry,
				index,
				selectedEntries,
				refBrandsCurrent,
				brandSelectedIndex,
				refCloseButton,
				wrapper,
				firstElement,
				focusedElement,
			}
			handleFirstColumn(handleProps)
		}
	}
}
