export const HEADER_TAGNAME = 'header'
export const BACK_BUTTON_SIZE = 16

export const enum TEST_IDS {
	CLOSE_SEARCH_BAR = 'header.searchBar.close',
	OPEN_SEARCH_BAR = 'header.userMenu.search.button',
	SEARCH_INPUT = 'header.searchBar.input',
	SEARCH_BAR = 'header.searchBar',
	SEARCH_BAR_SUBMENU = 'header.searchBar.subMenu',
}
