import { useContext } from 'react'

import { MenuRefStateContext } from './MenuRefContext'

export const useMenuRefState = () => {
	const context = useContext(MenuRefStateContext)

	if (context === undefined) {
		throw new Error('useMenuState must be used within a MenuProvider')
	}

	return context
}
