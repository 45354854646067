'use client'

import { useUrlBrand } from 'hooks/useUrlBrand/useUrlBrand'
import { createContext, useCallback, useState } from 'react'
import type { MasterData } from 'types/masterData'

export type HeaderState = {
	isMenuOpen: boolean
	isSearchOpen: boolean
	isForthColumnVisible: boolean
	brandId: MasterData['brand']
	subBrandId: MasterData['brand']
}

const INITIAL_STATE: HeaderState = {
	isMenuOpen: false,
	isSearchOpen: false,
	isForthColumnVisible: false,
	brandId: null,
	subBrandId: null,
}

export type HeaderDispatch = (newState: Partial<HeaderState>) => void

const INITIAL_DISPATCH: HeaderDispatch = () => {
	/* */
}

type HeaderProviderProps = {
	children: React.ReactNode
}

export const HeaderStateContext = createContext<HeaderState>(INITIAL_STATE)
export const HeaderDispatchContext =
	createContext<HeaderDispatch>(INITIAL_DISPATCH)

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
	const { brand: pageBrand, subBrand: pageSubBrand } = useUrlBrand()
	const [state, setState] = useState({
		...INITIAL_STATE,
		brandId: pageBrand?.id ?? null,
		subBrandId: pageSubBrand?.id ?? null,
	})
	const dispatch = useCallback(
		(newState: Partial<HeaderState>) =>
			// TODO: control rerenders probably here. try using client singleton
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	return (
		<HeaderStateContext.Provider value={state}>
			<HeaderDispatchContext.Provider value={dispatch}>
				{children}
			</HeaderDispatchContext.Provider>
		</HeaderStateContext.Provider>
	)
}
