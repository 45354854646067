import type { Brand, BrandId } from 'types/brands'
import { urlNormalize } from 'utils/url/urlNormalize'

import { getBrandTranslationKeys } from './getBrandTranslationKeys'

const translateBrandPath = (
	brand: Brand,
	brands: Brand[],
	translate: (key: string) => string
) => {
	const urlKey = getBrandTranslationKeys(brand.id, brands)?.urlKey

	if (!urlKey) {
		return undefined
	}

	return urlNormalize(translate(urlKey))
}

export const brandPath = ({
	brandId,
	brands,
	translate,
	initialPath = '',
}: {
	brandId: BrandId
	brands: Brand[]
	translate: (key: string) => string
	initialPath?: string
}): string =>
	brands.reduce((path, brand) => {
		const pathBrand = translateBrandPath(brand, brands, translate)

		if (!pathBrand) {
			return path
		}

		if (brand.id === brandId) {
			return `${path}/${pathBrand}`
		}

		const subBrand = brand.subBrands?.find((b) => b.id === brandId)

		if (subBrand && brand.subBrands) {
			return brandPath({
				brandId,
				translate,
				brands: brand.subBrands,
				initialPath: `${path}/${pathBrand}`,
			})
		}

		return path
	}, initialPath)
