import cx from 'classnames'
import React from 'react'

import { useMenus } from '../../../../hooks/useMenus'
import { ColumnsSM } from '../Columns/ColumnsSM'
import { SubBrands } from '../SubBrands/SubBrands'

import stylesStructureSM from '../Structure/StructureSM.module.scss'

export function ContentSM() {
	const {
		open,
		brandSelected,
		subBrandSelected,
		getBrandMenu,
		getBrandSubBrands,
	} = useMenus()

	const subBrands = getBrandSubBrands(brandSelected)
	const { menus, subLabel } = getBrandMenu(brandSelected, subBrandSelected)

	return (
		<div
			className={cx(
				stylesStructureSM.content,
				!open && stylesStructureSM.hidden
			)}
		>
			{subBrands && <SubBrands subBrands={subBrands} />}
			<ColumnsSM menus={menus} subLabel={subLabel} />
		</div>
	)
}
