'use client'

import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import dynamic from 'next/dynamic'

interface StoreLocatorModalProps {
	storeLocatorModalRef: React.RefObject<ModalAPI>
}

const StoreLocator = dynamic(() =>
	import('maps/components/StoreLocator/StoreLocator').then(
		(mod) => mod.StoreLocator
	)
)

export const StoreLocatorModal = ({
	storeLocatorModalRef,
}: StoreLocatorModalProps) => {
	const { t } = useLabels()
	return (
		<Modal
			data-testid='footer.storeLocatorModal'
			name='store-locator-modal'
			title={t('storeLocator.search.title')}
			type={MODAL_TYPE.XL}
			ref={storeLocatorModalRef}
		>
			<StoreLocator />
		</Modal>
	)
}
