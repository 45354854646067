import type { BrandId } from 'types/brands'
import { cx } from 'utils/cx'

import { useMenus } from '../../../../hooks/useMenus'
import { Brands } from '../Brands/Brands'
import { CloseButton } from '../CloseButton/CloseButton'

import styles from './TopBar.module.scss'

type TopBarProps = {
	isSM: boolean
}
export function TopBar({ isSM }: TopBarProps) {
	const { getTopBrands, open } = useMenus()

	const brandsMenu = getTopBrands()

	const brands = brandsMenu.map((brand) => {
		return {
			id: brand.id as BrandId,
			labelKey: brand.labelId,
			url: brand.url ?? '',
		}
	})

	return (
		<div className={cx(styles.topBar, isSM && !open && styles.close)}>
			<Brands brands={brands} isTopBar />
			<CloseButton />
		</div>
	)
}
